import React, { useState, useRef } from "react";
import { useSignup } from "../../../contexts/SignupContext";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import Turnstile from "react-turnstile";

export default function SignupSummary() {
  const { signupData, resetSignupData } = useSignup();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const turnstileRef = useRef();
  const [captchaToken, setCaptchaToken] = useState(null);
  const { goToNext, _ } = useSignupNavigation();

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async () => {
    if (!captchaToken) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          credentials: "omit",
          body: JSON.stringify({ ...signupData, captchaToken }),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 5);
        localStorage.setItem(
          "signupPending",
          JSON.stringify({
            pending: true,
            expiresAt: expiryDate.toISOString(),
          }),
        );
        localStorage.removeItem("signupData");
        resetSignupData();
        goToNext();
      } else {
        throw new Error(data.message || "Signup failed");
      }
    } catch (error) {
      console.error("Error submitting signup:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <SignupPageLayout
      title="Zusammenfassung"
      step={5}
      totalSteps={5}
      progress={100}
      description="Bitte überprüfen Sie Ihre Angaben."
      footer={
        <>
          <div className="flex justify-center pt-4">
            <Turnstile
              ref={turnstileRef}
              sitekey="0x4AAAAAAAyqAmwH65NZhKnh"
              onVerify={handleCaptchaVerify}
              onError={() => setCaptchaToken(null)}
              onExpire={() => setCaptchaToken(null)}
            />
          </div>
          <SignupFooter
            isValid={captchaToken !== null}
            validationMsg="Bitte lösen Sie den Captcha."
            onSubmit={handleSubmit}
            submitText={
              isSubmitting ? "Wird gesendet..." : "Registrierung abschliessen"
            }
            disabled={isSubmitting || !captchaToken}
          />
        </>
      }
    >
      <div className="space-y-2">
        {/* Contact Information */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold">Kontaktdaten</h3>
          </div>
          <div className="space-y-1 text-sm">
            <p>{`${signupData.contact.firstname} ${signupData.contact.lastname}`}</p>
            <p>{signupData.contact.email}</p>
            <p>{signupData.contact.phone}</p>
            <p>{signupData.contact.jobTitle}</p>
          </div>
        </div>

        {/* Business Information */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold">Adresse</h3>
          </div>
          <div className="space-y-1 text-sm">
            <p>{signupData.institution.name}</p>
            <p>
              {signupData.institution.street} {signupData.institution.number}
            </p>
            <p>
              {signupData.institution.zip} {signupData.institution.city}
            </p>
          </div>
        </div>

        {/* Departments */}
        {signupData.departments.length > 0 && (
          <div className="bg-white shadow p-4">
            <div className="flex justify-between items-start">
              <h3 className="font-semibold">Abteilungen</h3>
            </div>
            <div className="space-y-1 text-sm">
              {signupData.departments.map((dept, index) => (
                <p key={dept.id}>{dept.name}</p>
              ))}
            </div>
          </div>
        )}

        {/* Employees */}
        {signupData.employees.length > 0 && (
          <div className="bg-white shadow p-4">
            <div className="flex justify-between items-start pb-2">
              <h3 className="font-semibold">Mitarbeiter</h3>
            </div>
            <div className="space-y-3 text-sm">
              {signupData.employees.map((employee) => (
                <div
                  key={employee.id}
                  className="border-b pb-2 last:border-b-0 last:pb-0"
                >
                  <p className="font-medium">
                    {employee.groupname
                      ? `${employee.groupname} ${employee.firstname} ${employee.lastname}`
                      : `${employee.firstname} ${employee.lastname}`}
                  </p>
                  <p>{employee.email}</p>
                  {employee.rights.includes("can_access_all_departments") && (
                    <p className="text-gray-500">
                      {signupData.departments
                        .filter((dept) =>
                          employee.departments?.includes(String(dept.id)),
                        )
                        .map((dept) => dept.name)
                        .join(", ")}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </SignupPageLayout>
  );
}
